// Here you can add other styles
.app-header .navbar-brand {
    width: auto;
    padding-left: 1rem;
}

.max-height1 {
    max-height: 45px;
    min-height: 45px;
}

.sidebar .sidebar-nav {
    overflow: inherit;
}

.sidebar .nav-dropdown-items a.nav-link {
    padding-left: 1.5rem;
    &.active {
        background: inherit;
        color: #20a8d8;
        &:hover {
            color: #fff;
        }
    }
}

.spinner {
    margin: 10px auto;
}

.spinner>div {
    width: 18px;
    height: 18px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}

.table td,
.table th {
    vertical-align: middle;
}

.buttonHolder {
    float: right;
    a {
        color: white;
        background: #20a8d8;
        border: 1px solid #20a8d8;
        border-radius: 0.25rem;
        padding: 0.375rem 1.5rem;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        &:hover {
            background-color: #1b8eb7;
            border-color: #1985ac;
            text-decoration: none;
        }
    }
}